<template>
  <div class="ibox ibox-border" ref="workerContract">
    <div class="ibox-title">
      <h4>{{$t('eurofiscalis_workers.contract.title')}}</h4>
      <div class="ibox-tools">
        <a class="collapse-link">
            <i class="fa fa-chevron-up"></i>
        </a>
      </div>
    </div>
    <div :class="'ibox-content p-md'">
        <ul class="list-group clear-list">
          <li class="list-group-item fist-item">
            {{$t('eurofiscalis_workers.contract.status-active')}}
        </li>
        <li class="list-group-item">
          {{$t('eurofiscalis_workers.contract.start-date',[contract.formattedStartDate])}}
        </li>
        <li class="list-group-item">
          {{$t('eurofiscalis_workers.contract.end-date',[contract.formattedEndDate])}}
        </li>
        <li class="list-group-item">
          {{$t('eurofiscalis_workers.contract.remaining-days',[contract.remainingDays])}}
        </li>
        <li class="list-group-item">
          {{$t('eurofiscalis_workers.contract.representative-countries',[representativeCountries])}}
        </li>
        <li class="list-group-item">
          {{$t('eurofiscalis_workers.contract.allowed-workers',[declaredWorkers])}}
        </li>
        <li class="list-group-item">
          <a href="javascript:void(0)" @click="showConditionsModal()">{{ $t('eurofiscalis_workers.register.conditions.title') }}</a>
        </li>
      </ul>
    </div>

    <ConditionsModal ref="conditionsModal" />
  </div>
</template>

<style scoped>
 
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../../theme/inspinia-jquery'; 
import ConditionsModal from './ConditionsModal.vue';
import { types as eurofiscalisTypes } from '../../../../store/eurofiscalis';

@Component({
  components : {
    ConditionsModal
  }
})
export default class Contract extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly contract!: any

  get representativeCountries() {
    return this.contract.representativeCountryCodes.map((countryCode:string) => {
      return this.$t("COUNTRIES."+countryCode);
    }).join(", ");
  }

  get declaredWorkers() {
    if(this.numberOfWorkers != null) {
      if(this.contract.allowedNumberOfWorkers == "Infinity") {
        return this.numberOfWorkers;
      }
      else {
        return this.numberOfWorkers + "/" + this.contract.allowedNumberOfWorkers;
      }
    }
    return "";
  }

  get numberOfWorkers() {
    return this.$store.getters['eurofiscalis/' + eurofiscalisTypes.getters.GET_NUMBER_OF_WORKERS];
  }

  mounted() {
    enableIbox(this.$refs.workerContract as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.workerContract as HTMLElement);
  }

  showConditionsModal() {
    // @ts-ignore
    this.$refs.conditionsModal.show();
  }
}
</script>