<template>
  <b-modal size="lg" ref="conditionsModal" :title="$t('eurofiscalis_workers.register.conditions.title')" hide-footer lazy>
    <template v-slot:modal-header="{ close }">
      <h5 class="modal-title">{{$t('eurofiscalis_workers.register.conditions.title')}}</h5>
      <ul class="nav navbar-top-links navbar-right">
        <li>
          <router-link :to="'/'+currentLanguageCode+'/workers/conditions?print=true'" target="_blank">
            <i class="fa fa-print"></i>
          </router-link>
        </li>
        <li><a href="javascript:void(0)" @click="close()"><i class="fa fa-times"></i></a></li>
      </ul>
    </template>
    <div v-html="$t('eurofiscalis_workers.register.conditions.content')"></div>
  </b-modal>
</template>

<style scoped>
.modal-title {
  flex-grow: 1;
}
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mapGetters } from '@fwk-node-modules/vuex';
import { languagesTypes } from '@fwk-client/store/types';



@Component({
  components: { 
    
  },
  computed: {
    ...mapGetters({
            currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
    })
  }
})
export default class ConditionsModal extends Vue {

  show() {
    // @ts-ignore
    this.$refs.conditionsModal.show();
  }
}
</script>